import React from "react";
import "./edit.css";
//edit icon
const edit = (
  <i
    className="far fa-edit"
    id="edit-btn-img"
    samesite="none"
    secure="true"
  ></i>
);
export default edit;
