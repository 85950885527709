import React from "react";
import "./comment.css";
//comment icon
const comment = (
  <img
    src="/images/icons/comment.png"
    alt="comment-icon"
    id="comment-btn-img"
  />
);
export default comment;
