import React from "react";
import "./like.css";
//like icon
const like = (
  <img
    src="/images/icons/coconut.png"
    alt="coconut-likes"
    id="coconut-likes-btn-img"
  />
);
export default like;
