export default {
  API_ENDPOINT: "https://powerful-garden-10065.herokuapp.com",
  TOKEN_KEY: "users-auth-token",
  ID_PARAM: "user_id",
  CLOUDINARY: {
    NAME: "users-posts",
    UPLOAD_PRESET: "allImages",
    API_KEY: "512649263768852",
    API_SECRET: "a3aat1r3NcEu3MR9zcsrrvc_ELs",
    URL: "cloudinary://512649263768852:a3aat1r3NcEu3MR9zcsrrvc_ELs@users-posts"
  }
};
//https://powerful-garden-10065.herokuapp.com
